<template>
    <div style="color: #a2a2a2">
        <div style="margin: 10px; padding: 0 0 10px; border-bottom: 1px dashed #ccc; color: #a6a6a6; font-weight: bold;">
            申诉结果查询
        </div>
        <div v-if="!show" style="padding: 50px 0px;" class="clear">
            <el-col :span="5" :offset="0" style="text-align: right;line-height: 30px;">申诉账号：</el-col>
            <el-col :span="5">
                <el-input v-model="account" style="height: 20px;"></el-input>
            </el-col>
            <br><br>
            <el-col :span="3" :offset="6" style="margin-top: 40px">
                <el-button type="primary" @click="searchAccountAppeal">请输入申诉账号信息</el-button>
            </el-col>
        </div>
        <div v-if="show" style="padding: 50px;" class="clear show-account-appeal">
            <el-row>
                <el-col :span="3" :offset="8" style="text-align: right;">申诉账号：</el-col>
                <el-col :span="5">{{account}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3" :offset="8" style="text-align: right;">提交申诉时间：</el-col>
                <el-col :span="5">{{accountAppeal.commitTime && dateToString(new Date(accountAppeal.commitTime), 'yyyy-MM-dd HH:mm:ss')}}</el-col>
            </el-row>
            <el-row v-show="accountAppeal.appealStatus === 2 || accountAppeal.appealStatus === 3">
                <el-col :span="3" :offset="8" style="text-align: right;">
                    审核申诉时间：
                </el-col>
                <el-col :span="5">{{accountAppeal.auditTime && dateToString(new Date(accountAppeal.auditTime), 'yyyy-MM-dd HH:mm:ss')}}</el-col>
            </el-row>
            <el-row>
                <el-col :span="3" :offset="8" style="text-align: right;">申诉结果：</el-col>
                <el-col :span="5"
                        :style="{color: accountAppeal.appealStatus === 1 ? '#4042D5' : accountAppeal.appealStatus === 2 ? 'green' : 'red' }">
                    {{accountAppeal.appealStatus === 1 ? '待审核' : accountAppeal.appealStatus === 2 ? '审核通过' : accountAppeal.appealStatus === 3 ? '审核不通过' : '未知'}}
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="3" :offset="8" style="text-align: right;" v-show="accountAppeal.appealStatus === 3">原因：
                </el-col>
                <el-col :span="5" v-show="accountAppeal.appealStatus === 3">{{accountAppeal.auditReason}}</el-col>
            </el-row>
            <el-row>
                <div style="margin: 20px;text-align: center;" v-show="accountAppeal.appealStatus === 2">
                    您的申诉已通过，可根据回执码前往"<router-link :to="'/account-appeal/reset'">重置资料</router-link>"，修改密码。<br>
                    回执码已发送到您预留的手机号中
                </div>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
  name: 'accountAppealQuery',
  components: {},
  data () {
    return {
      show: false,
      account: null,
      accountAppeal: {
        commitTime: null,
        auditTime: null,
        appealStatus: null,
        auditReason: null
      }
    }
  },
  methods: {
    searchAccountAppeal () {
      this.$request.post({
        reqcode: '1101',
        reqdesc: '查询账号申诉',
        account: this.account
      }).then(res => {
        this.accountAppeal = res.retdata
        this.show = true
      }).catch(res => {
        this.$message.warning(res.msg)
      })
    }
  }
}
</script>

<style scoped>
    input.el-input__inner {
        height: 30px;
    }

    .show-account-appeal > div {
        margin-top: 10px;
    }
</style>
